/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

/* Avenir Book (300) */
@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/065a6b14-b2cc-446e-9428-271c570df0d9.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff" ) format( "woff" );
    font-weight: 300;
}

@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/f1ebae2b-5296-4244-8771-5f40e60a564a.woff" ) format( "woff" );
    font-style: oblique;
    font-weight: 300;
}

/* Avenir Roman (400) */
@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff" ) format( "woff" );
    font-weight: 400;
}

@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/cdda031e-26e9-4269-83d1-5a218caa10db.woff" ) format( "woff" );
    font-style: oblique;
    font-weight: 400;
}

@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/695a9fa5-9f57-4800-9993-3684d520f107.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/e0af2f49-a399-482b-a54e-d745e268ec80.woff" ) format( "woff" );
    font-style: oblique;
    font-weight: 500;
}

/* Avenir Heavy (800) */
@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/d513e15e-8f35-4129-ad05-481815e52625.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/61bd362e-7162-46bd-b67e-28f366c4afbe.woff" ) format( "woff" );
    font-weight: 800;
}

@font-face {
    font-family: "Avenir W01";
    src: url( "/wp-content/themes/karenmcgrane/dist/assets/type/3c210c80-960f-4684-850b-25390b4d08af.woff2" ) format( "woff2" ),
         url( "/wp-content/themes/karenmcgrane/dist/assets/type/cb5c71ad-e582-4d00-929c-67fbfaeb1c27.woff" ) format( "woff" );
    font-style: oblique;
    font-weight: 800;
}
