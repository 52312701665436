body {
    background: #311B45;
    color: #FFFFF0;
    font-size: calc( 14px + ( 16 - 14 ) * ( ( 100vw - 300px ) / ( 1400 - 300) ));
    font: 400 1em/1.45 $fonts-sans-initial;
    margin: 0 auto;
    max-width: 36em;
}
.fonts-loaded body {
    font-family: $fonts-sans;
}
@media (min-width: 30em) {
    body {
        padding-left: 2.5vw;
        padding-right: 2.5vw;
    }
}
@media (min-width: 50em) {
    body {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}
@media (min-width: 75em) {
    body {
        padding-left: 7vw;
        padding-right: 7vw;
    }
}
@supports ( display: grid ) {
    body {
        max-width: inherit;
    }
}
@supports ( mix-blend-mode: multiply ) {
    body.colorful {
        background: #E7536B;
        background-image: url( "../svg/bg.svg" );
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 100% auto;
        background-attachment: fixed;
    }
    body.colorful.sect-home {
        background-position: left 50vw top 50%;
    }
}

[hidden],
.squelch,
.frm_hidden {
    display: none;
}
.a11y,
.frm_screen_reader,
// I don’t know why both of these classes would be assigned, but when they are, let’s assume we should be as accessible as possible
.frm_screen_reader.frm_hidden {
    clip: rect( 1px 1px 1px 1px );
    clip: rect( 1px,1px,1px,1px );
    display: block;
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}

a {
    color: #00ACBF;
    transition: color 0.2s, background-color 0.2s;
}
a:hover,
a:focus {
    color: #714698;
    text-decoration: none;
}

img,
iframe {
    max-width: 100%;
}

input,
textarea {
    font-size: inherit;
    font-family: inherit;
}

fieldset {
    border: none;
    padding: 0;
}
legend {
    position: absolute;
    left: -1000em;
}

pre {
    background: #EEE;
    margin-left: -1em;
    margin-right: -1em;
    overflow: auto;
    padding: 1em;
}

/*

Basic layout scaffolding

*/
.region {
    color: #333;
    margin: 0 auto;
    padding: 3vw 3.225806451612903225%;    /* 40 / 1240 */
}

/*
Various and sundry grid layouts
*/
.l-50-50 > .group {
    border-top: 0.5em solid #D5D3D7;
    padding: 0.75em 0;
}
.tmpl-books .l-50-50 > .group {
    display: flex;
    flex-direction: column;
}
@supports ( display: grid ) {
    @media (min-width: 40em) {
        .l-60-40 {
            display: grid;
            grid-template-columns: 65.517241379310344827% 31.034482758620689655%;    /* 760/1160, 360/1160 */
            grid-column-gap: 3.448275862068965517%;    /* 40/1160 */
        }
        .l-60-40 > .group {
            grid-column: 1;
        }
        .l-60-40 > .group-alt {
            grid-column: 2;
        }

        .l-50-50 {
            display: grid;
            grid-template-columns: repeat( 2, 48.275862068965517241% );    /* 760/1160, 360/1160 */
            grid-column-gap: 3.448275862068965517%;    /* 40/1160 */
        }
        .l-50-50 > .group {
            margin-bottom: 1em;
        }
        .l-50-50 > .group:nth-child( 2n + 1 ) {
            grid-column: 1;
        }
        .l-50-50 > .group:nth-child( 2n ) {
            grid-column: 2;
        }
        .l-50-50 > .group-full {
            grid-column: 1 / 3;
        }
    }
    @media (min-width: 50em) {
        .l-sole {
            margin-left: -3.448275862068965517%;     /* 40/1160 */
            margin-right: -3.448275862068965517%;    /* 40/1160 */
        }
        .l-sole > .group {
            margin: 0 auto;
            width: 61.290322580645161290%;    /* 760/1240 */
        }
    }
}



/*

Masthead

*/
body .region.site-nav {
    background-color: #1B082D;
}
.site-nav {
    border: 10px solid #382645;
    border-width: 10px 0;
    font-weight: 800;
}
.site-mast {
    margin-bottom: 1em;
}
.site-mast,
.site-mast a,
.site-footer a {
    color: #FFFFF0;
}
.site-mast a {
    text-decoration: none;
}
@supports ( display: grid ) {
    @media ( min-width: 50em ) {
        .site-mast {
            margin: 2em auto 0;
            padding-left: 0;
            padding-right: 0;
        }
        .sect-home .site-mast {
            margin-top: 0;
        }
    }
}

.logo {
    display: block;
    font-size: 1.5em;
    line-height: 1.1;
    margin: 1em 0 0;
    max-width: 20em;
}
@media ( min-width: 60em ) {
    .logo {
        max-width: inherit;
    }
}
.logo .name-km {
    display: inline-block;
}
.sect-home .logo {
    max-width: inherit;
}
.sect-home .logo .name-km {
    display: block;
}
@media ( min-width: 50em ) {
    .logo {
        font-size: 2em;
    }
}
.logo a {
    display: block;
}
.nav-list {
    font-size: 0.8em;
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 34em;
}
@media ( min-width: 30em ) {
    .nav-list {
        font-size: 1.125em;  /* 18/16 */
    }
}
.nav-item {
    display: inline-block;
    margin-right: 2%;
    text-align: center;
    width: 17%;
}
.nav-item:last-child {
    margin-right: 0;
}
.nav-list a {
    display: block;
    font-size: 0.8em;
    font-weight: 300;
    letter-spacing: 0.075em;
    text-transform: uppercase;
}
.nav-list a:hover,
.nav-list a:focus,
.nav-list [ aria-describedby="current-page" ] a {
    text-transform: uppercase;
}
.nav-list [ aria-describedby="current-page" ] a {
    cursor: default;
    font-style: italic;
}
.nav-icon {
    display: block;
    margin-bottom: 2em;
    width: 100%;
    height: auto;
}
.nav-icon path {
    fill: inherit;
}
[ aria-describedby="current-page" ] .nav-icon,
[ aria-describedby="current-page" ] a:hover .nav-icon,
[ aria-describedby="current-page" ] a:focus .nav-icon {
    fill: #FFF;
    transform: none;
}
@supports ( display: flex ) {
    .nav-list {
        display: flex;
        flex: 1;
        max-width: inherit;
    }
    .nav-list li {
        display: flex;
        flex: 1 0 17%;
    }
    .nav-list a {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    @media ( min-width: 40em ) {
        .site-mast nav,
        .sect-home .site-mast {
            display: flex;
        }
        .sect-home .logo {
            align-self: flex-end;
            flex: 1 0 30%;
            margin: 0;
        }
        .site-mast nav {
            margin: 0 0 1em;
        }
        .sect-home .site-mast nav {
            flex: 1 0 68%;
            margin: 0;
            width: inherit;
        }
        .nav-list {
            align-self: flex-end;
        }
    }
}

svg.nav-icon {
    fill: $nav-color;
}
a:hover svg path.color-swap,
a:hover svg g.color-swap,
.nav-icon path a:focus {
    fill: lighten( $nav-color, 15% );
}

.nav-icon path {
    transition: fill 0.25s ease;
}

.key-on,
a:hover .key-off,
a:focus .key-off {
    display: none;
}
a:hover .key-on,
a:focus .key-on {
    display: block;
}
// “Rotate” animation (used in Sources icon)
.rotate-left,
.rotate-right {
    animation: rotating 12s infinite linear;
    animation-play-state: paused;
}
.rotate-left {
    transform-origin: 148px 133px;
}
.rotate-right {
    transform-origin: 378px 133px;
}
a:hover .rotate-left,
a:focus .rotate-left,
a:focus .rotate-right,
a:hover .rotate-right {
    animation: rotating 12s infinite linear;
    animation-play-state: playing;
}
// “Flicker” animation (used in Talks icon)
a:hover .flicker,
a:focus .flicker {
    animation: flicker 0.3s linear;
}
@media ( prefers-reduced-motion: reduce ) {
    a:hover .flicker,
    a:focus .flicker,
    .blink {
        animation: none;
    }
}
a:hover .turn {
    transform: rotate(-40deg);
    transform-origin: 400px 180px;
    transition: transform 0.2s ease-in-out;
}
a:hover .move-right {
    transform: translate(-15px, 0);
}
a:hover .move-left {
    transform: translate(10px, 0);
}
.move-left,
.move-right {
    transition: all 0.15s ease;
}

.blink {
    animation: blink-animation 0.2s steps(5, start) infinite;
}



/*

Page content

*/
.site-content .region {
    background-color: #FFF;
    margin-bottom: 5em;
    position: relative;
}
.site-content .region:before,
.site-content .region:after {
//    background-color: #2F2F2F;
//    background-color: rgba( 47, 47, 47, 0.6 );
////    content: "";
//    height: 0.5em;
//    left: 0;
//    right: 0;
//    position: absolute;
}
.site-content .region:before {
    top: -0.5em;
}
.site-content .region:after {
    bottom: -0.5em;
}
.site-content .region:last-child {
    margin-bottom: 0;
}

// Main page heading
.hed-lead {
    clear: both;
    font-size: 2.25em;    /* 36 / 16 */
    line-height: 1.175;
    margin-bottom: 1em;
}
// Section subhed — uppercased, smaller hed
.hed-section-sub {
    clear: both;
    color: #545454;
    font-size: 1.25em;    /* 20 / 16 */
    font-weight: 800;
    line-height: 1.35;
    margin: 2em 0 1em;
    padding: 0.25em 0;
    text-transform: uppercase;
}
.hed-section-sub:first-child {
    margin-top: 0;
}

/* List with source titles + article counts */
.sources-count-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
.sources-count-list a {
    border-bottom: 1px solid #DEDEDE;
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 0.25em 0;
}
.sources-count-list a:hover,
.sources-count-list a:focus {
    color: #2785A9;
}
.source-count {
    display: block;
    display: flex;
    margin: 0;
}
.source-count dt,
.source-count dd {
    display: inline-block;
    vertical-align: top;
}
.source-count dt {
    flex: 1 1 80%;
    width: 80%;
}
.source-count dd {
    color: #999;
    flex: 1 0 15%;
    margin-left: 0;
    text-align: right;
    transition: color 0.2s;
    width: 15%;
}
.sources-count-list a:hover dd,
.sources-count-list a:focus dd {
    color: inherit;
}
.group-full .source-count dt {
    flex: 1 1 60%;
}
.group-full .source-count dd {
    flex: 1 1 40%;
}
.group-full .source-count .a11y {
    position: static !important;
}
@media ( min-width: 40em ) {
    .group-full .sources-count-list {
        display: flex;
        flex-wrap: wrap;
    }
    .group-full .sources-count-list li {
        flex: 0 0 48%;
        margin-right: 4%;
    }
    .group-full .sources-count-list li:nth-child( 2n ) {
        margin-right: 0;
    }
}

.header-more {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
}



/*

Teasers!

*/
.teaser-list {
    list-style: none;
    margin-left: 0;
    margin-top: 0;
    padding-left: 0;
}
.teaser {
    border-bottom: 1px solid #D5D3D7;
    line-height: 1.4;
    margin-bottom: 2em;
    padding-bottom: 2em;
}
.teaser-list .teaser:last-child {
    border-bottom: none;
}
.teaser:after {
    clear: both;
    content: "";
    display: table;
}
.teaser img {
    float: right;
    margin-left: 5%;
    margin-bottom: 0.5em;
    width: 25%;
}
@media (min-width: 30em) {
    .teaser img {
        margin-left: 0;
        margin-bottom: 0;
        width: 21.024967148488830486%;    /* 160/761 */
    }
    img + .teaser-inner {
        margin-right: 26.315789473684210526%;    /* 200/760 */
    }
}
.teaser-inner > time,
.article-meta,
.teaser-meta {
    color: #999;
    font-size: 0.875em;    /* 14/16 */
}
.teaser-inner > time,
.article-meta {
    text-transform: uppercase;
}
.article-meta a,
.article-meta time {
    display: block;
}
.hed-teaser {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.25;    /* 45 / 36 */
    margin: 0.2em 0;
}
.teaser p {
    font-size: 0.9375em;  /* 15/16 */
    margin-bottom: 0.5em;
    margin-top: 0;
}
@media (min-width: 40em) {
    .teaser p {
        font-size: 1em;  /* 18/16 */
    }
}
.teaser p:last-child {
    margin-bottom: 0;
}
.read-more,
.link-more {
    white-space: nowrap;
}
.link-more:after,
.read-more:after {
    content: "\00a0›";
    display: inline-block;
    text-decoration: none;
    padding-left: 0.25em;
}
.link-more {
    color: #C03186;
    border: 1px solid #C03186;
    border-radius: 0.25em;
    font-size: 0.875em;    /* 14/16 */
    padding: 0.25em 0.35em;
    text-decoration: none;
}
.link-more:hover,
.link-more:focus {
    background-color: #C03186;
    color: #FFFFF0;
}
.link-more:after {
    padding-left: 0;
}
/* “Caps” finish off a section, and are aligned to the right on wider screens */
.cap {
    clear: both;
    text-align: right;
    margin-bottom: 3em;
}



/*

Entry styles

*/
.article-content:before,
.article-content:after {
    content: "";
    display: table;
    clear: both;
}
.hed-article-title {
    font-size: 2em;
    line-height: 1.175;
    margin-bottom: 0.2em;
}
.hed-article-title + * {
    margin-top: 0;
}
.article-lead {
    font-size: 1.15em;
}
.article-content {
    line-height: 1.4;
}
@media ( min-width: 40em ) {
    .hed-article-title {
        font-size: 3em;
    }
    .article-lead {
        font-size: 1.6em;
    }
    .article-content {
        font-size: 1.15em;
    }
}
.article-content {
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 3em;
    padding-bottom: 3em;
}
.article-content > *:last-child {
    margin-bottom: 0;
}
.article-content > :first-child {
    margin-top: 0;
}
blockquote {
    color: #444;
    font-size: 1.05em;
    margin: 2.5em 0;
    padding-left: 4em;
    position: relative;
}
blockquote:before {
    color: #444;
    content: "“";
    display: block;
    font-family: Georgia, Times, serif;
    font-size: 5em;
    font-style: italic;
    font-weight: 800;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0;
}
.fonts-loaded blockquote:before {
    top: -0.05em;
}
.article-content blockquote {
    font-weight: 800;
    padding-left: 0;
}
.article-content blockquote:before {
    left: -0.75em;
}
blockquote address {
    color: #666;
    font-size: 0.9em;
    font-style: italic;
    font-weight: 400;
}
@media (min-width: 45em) {
    blockquote:before,
    blockquote:after {
        margin-left: 0;
    }
    blockquote address {
        margin-left: 1.4em;
    }
    blockquote address:before {
        content: "—";
        margin-left: -1.3em;
    }
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
    margin-top: 1.5em;
}
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
    letter-spacing: 0.01em;
    text-transform: uppercase;
}
.article-content code {
    color: #699BCD;
    font-family: Inconsolata, Consolas, "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
}
.article-content ol,
.article-content ul {
    margin: 1.5em 0;
    padding-left: 0;
}
.article-content li {
    margin-left: 1.1em;
    padding-left: 0.5em;
}
.article-content li ol,
.article-content li ul {
    margin: 0.5em 0;
}
// Embedded media
.embed-video {
    background: #ADA7B3;
    padding: 1em;
    padding: 3vw;
}
.article-content iframe {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
// Images!
.article-content img {
    height: auto;
}
.full img {
    display: block;
    max-width: inherit;
    width: 100%;
}
.full {
    margin: 2em 0;
    margin-left: -3.448275862068965517%;    /* 40/1240 */
    width: 106.896551724137931034%;    /* 1240/1160 */
}
@supports ( display: grid ) {
    @media (min-width: 50em) {
        .full {
            margin-left: -31.555%;    /* 240/1160 */
            width: 163.157894736842105263%;    /* 1240/760 */
        }
    }
}
.alignnone,
.alignleft,
.alignright {
    display: block;
    width: 100%;
}
.wp-caption {
    background: #FFF;
    color: #999;
    font-size: 0.8em;
    line-height: 1.3;
    margin: 1.5em 0;
}
.wp-caption img {
    border-bottom: 1px solid #DEDEDE;
    display: block;
    padding-bottom: 0.75em;
    margin-bottom: 0.75em;
    width: 100%;
}
@supports ( display: grid ) {
    @media (min-width: 40em) {
        .alignleft,
        .alignright {
            margin-bottom: 1.5em;
            margin-top: 0;
            width: 47.5%;    /* 361/760 */
        }
        .wp-caption {
            margin-bottom: 2em;
        }
        .alignleft {
            clear: left;
            float: left;
            margin-right: 5%;    /* 40/760 */
        }
        .alignright {
            clear: right;
            float: right;
            margin-left: 5%;    /* 40/760 */
        }
    }
    @media (min-width: 50em) {
        .alignleft {
            margin-left: -13.157894736842105263%;    /* 100/760 */
            margin-right: 5.263157894736842105%;    /* 40/760 */
        }
        .alignright {
            margin-left: 5.263157894736842105%;    /* 40/760 */
            margin-right: -13.157894736842105263%;    /* 100/760 */
        }
    }
}



/*

Project list

*/
.list-projects {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
.list-projects li {
    border-bottom: 1px solid #C0C0C0;
    padding-top: 1.5em;
    padding-bottom: 0.5em;
}
.hed-project {
    font-size: 1em;
    margin: 0;
}
@media ( min-width: 40em ) {
    .list-projects li {
        display: flex;
    }
    .hed-project {
        flex: 1 0 5em;
        margin-bottom: 1em;
        padding-right: 1em;
    }
    .list-projects p {
        flex: 1 0 75%;
        margin-top: 0;
    }
}



/*

Slides!

*/
.slide-list {
    list-style: none;
}
.slide {
    margin-bottom: 2em;
}
// Specificity fight 👎
ol.slide-list,
li.slide {
    margin-left: 0;
    padding-left: 0;
}
.article-content ol.slide-list {
    margin-top: 2em;
}
@media ( min-width: 40em ) {
    .slide p {
        margin-top: 0;
    }
    .slide-img {
        clear: right;
        float: right;
        margin: 0 0 0.5em 3%;
        width: 35%;
    }
}
@media ( min-width: 50em ) {
    li.slide {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 0 3em;
        padding-bottom: 2.5em;
        position: relative;
    }
    li.slide:after {
        border-bottom: 1px solid #C0C0C0;
        bottom: 0;
        content: "";
        height: 0;
        left: 20%;
        position: absolute;
        width: 60%;
    }
    .slide-img {
        flex: 1 0 30%;
        order: 1;
        width: inherit;
        margin: 0;
    }
    .slide-content {
        flex: 1 0 65%;
        margin-right: 3%;
        order: 2;
    }
    .slide-content > :last-child {
        margin-bottom: 0;
    }
}



/*

Compact nav (homepage lead-in)

*/
// Specificity fight 👎
.region.nav-compact {
    background: inherit;
}
// Specificity fight 👎
.site-content .region.nav-compact {
    margin: 0 0 1em;
}
.nav-compact ul,
.nav-compact li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.nav-compact ul {
    display: flex;
    flex-wrap: wrap;
}
.nav-compact li {
    margin-right: 2em;
}
.nav-compact a {
    color: #FFFFF0;
    text-transform: uppercase;
    transition: color 0.2s;
}



/*

About module

*/
.region.about-karen {
    background-color: #684F80;
}
.about-karen {
    background:
        url( "/wp-content/themes/karenmcgrane/_assets/fpo/intro-headshot.jpg" ) no-repeat 50% 0 / 100% auto;
    color: #FFF;
    margin-top: 20px;
    margin-bottom: 20px;
    outline: 20px solid #382645;
    padding-top: 52.2%;    /* 522/1000 */
}
@media (-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min--moz-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
    .about-karen {
        background-image: url( "/wp-content/themes/karenmcgrane/_assets/fpo/intro-headshot@2x.jpg" );
    }
}
.about-hed {
    font-size: 1.75em;    /* 36 / 16 */
    line-height: 1.27777777777777777777;     /* 46 / 36 */
    margin: 1em 0;
}
.name-km {
    display: block;
    font-style: inherit;
    text-transform: uppercase;
}
.name-km b {
    text-transform: none;
}
.about-content {
    line-height: 1.5;      /* 27 / 18 */
    margin-top: auto;
}
.about-content p {
    margin-bottom: 0.75em;
}
@media ( min-width: 43em ) {
    .about-karen {
        background:
            linear-gradient( 90deg, rgba( 59, 20, 88, 0.7 ) 40%, rgba( 59, 20, 88, 0.7 ) 56%, rgba( 59, 20, 88, 0 ) 56%, rgba( 59, 20, 88, 0.00 ) 60% ),
            url( "/wp-content/themes/karenmcgrane/_assets/fpo/intro-headshot.jpg" ) no-repeat 50% 0 / cover;
        padding-top: 3vw;
    }
    @media (-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min--moz-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
        .about-karen {
            background:
                linear-gradient( 90deg, rgba( 59, 20, 88, 0.7 ) 40%, rgba( 59, 20, 88, 0.7 ) 56%, rgba( 59, 20, 88, 0 ) 56%, rgba( 59, 20, 88, 0.00 ) 60% ),
                url( "/wp-content/themes/karenmcgrane/_assets/fpo/intro-headshot@2x.jpg" ) no-repeat 50% 0 / cover;
        }
    }
    .about-hed,
    .about-content {
        width: 55%;
    }
    .about-hed {
        margin: 0 0 2em;
        max-width: 14em;
    }
    .about-content {
        max-width: 20em;
    }
}
@media ( min-width: 54em ) {
    .about-hed {
        font-size: 2.25em;    /* 36 / 16 */
    }
    .about-content {
        font-size: 1.125em;    /* 18 / 16 */
    }
}
@media ( min-width: 68.75em ) {
    .about-karen {
        background:
            linear-gradient( 104deg, rgba( 59, 20, 88, 0.7 ) 0, rgba( 59, 20, 88, 0.7 ) 45%, rgba( 59, 20, 88, 0 ) 45%, rgba( 59, 20, 88, 0.00 ) 100% ),
            url( "/wp-content/themes/karenmcgrane/_assets/fpo/intro-headshot.jpg" ) no-repeat 50% 0 / 100% auto;
    }
    @media (-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min--moz-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
        .about-karen {
            background:
                linear-gradient( 104deg, rgba( 59, 20, 88, 0.7 ) 0, rgba( 59, 20, 88, 0.7 ) 45%, rgba( 59, 20, 88, 0 ) 45%, rgba( 59, 20, 88, 0.00 ) 100% ),
                url( "/wp-content/themes/karenmcgrane/_assets/fpo/intro-headshot@2x.jpg" ) no-repeat 50% 0 / 100% auto;
        }
    }
    .about-karen:before,
    .about-karen:after {
        content: "";
    }
    .about-karen:before {
        float: left;
        padding-top: 52.2%;    /* 522/1000 */
        padding-top: calc( 52.2% - 6vw );
    }
    .about-karen:after {
        clear: both;
        display: table;
    }
}



/*

Featured items (grid on homepage / section fronts)

*/
.region.list-featured {
    background: none;
    padding: 0;
    margin: 2em 0;
}
.sect-home .region.list-featured {
    margin: 0 0 2em;
}
.list-featured,
.list-featured li {
    list-style: none;
}
.featured-item {
    background: #FFF;
    grid-column-span: 1;
    padding: 3vw 3.225806451612903225%;    /* 40 / 1240 */
    margin-bottom: 2em;
}
@media ( min-width: 56.25em ) {
    .list-featured {
        display: grid;
        grid-template-columns: repeat( 2, 1fr );
        grid-gap: 2em;
    }
    .featured-item {
        margin-bottom: 0;
        padding: 3vw 6.4516129%;    /* 40/(1240/2) */
    }
}
.featured-item,
.featured-content {
    display: flex;
    flex-direction: column;
}
.featured-content {
    min-height: 12em;
    flex: 1;
}
.featured-item h2 {
    margin: 0;
    font-size: inherit;
}
.hed-featured {
    font-size: 1.25em;     /* 20/16 */
    line-height: 1.35;     /* 27/20 */
    text-decoration: none;
    text-transform: uppercase;
}
.featured-item p {
    flex: 1;
    margin: 1em 0;
    max-width: 30em;
}
.meta-featured {
    color: #9D9D9D;
    display: block;
    margin-top: auto;
}
.meta-featured a {
    color: inherit;
}
.meta-featured a:hover,
.meta-featured a:focus {
    color: #555;
}
// Featured items with images (i.e., talk excerpts)
.featured-item.is-priority {
    margin: 1em 0;
    outline: 20px solid #1B082D;
}
.featured-item.is-priority img {
    display: block;
    margin-bottom: 1em;
    width: 100%;
}
@media ( min-width: 40em ) {
    .featured-item.is-priority {
        display: grid;
        grid-template-columns: 1fr 2em 1fr;
        grid-column: 1 / -1;
        padding: 3vw 3.225806451612903225%;    /* 40 / 1240 */
    }
    .featured-item.is-priority > p {
        grid-column: 1 / -1;
        max-width: 40em;
    }
    .featured-item.is-priority .featured-content {
        grid-column: 1;
        grid-row: 1;
    }
    .featured-item.is-priority img {
        grid-column: 3;
        width: inherit;
    }
    .featured-item.is-priority .hed-featured {
        font-size: 1.5em;      /* 24/16 */
        line-height: 1.375;    /* 33/24 */
    }
    .featured-item.is-priority p {
        font-size: 1.25em;    /* 20/16 */
        line-height: 1.35;    /* 27/20 */
    }
}
@media ( min-width: 56.25em ) {
    .featured-item.is-priority .featured-content {
        grid-column: 3;
    }
    .featured-item.is-priority img {
        grid-column: 1 / 2;
    }
}




/*

Books

*/
.book {
    display: flex;
    flex-direction: column;
    justify-self: stretch;
}
.book img {
    border: 1px solid #EEE;
    display: block;
    max-width: 15em;
    margin: 1.5em auto;
    width: 100%;
}
.book-title {
    font-size: 1.2em;
    text-align: center;
}
.book-desc {
    margin-bottom: 2em;
}
.book p {
    font-size: 1.1em;
}
.book .cap {
    text-align: center;
    margin-top: auto;
}



/*

Contact

*/
.contact-form {
    max-width: 40em;
}
.contact-form label,
.contact-form [type=email],
.contact-form [type=text],
.contact-form textarea {
    display: block;
    width: 100%;
}
.contact-form [type=email],
.contact-form [type=text],
.contact-form textarea {
    border: 1px solid #EEE;
    box-sizing: border-box;
    padding: 0.4em;
    transition: border-color 0.2s;
    margin-bottom: 0.75em;
}
.contact-form [type=email]:focus,
.contact-form [type=text]:focus,
.contact-form textarea:focus {
    border-color: #999;
}
.contact-form textarea {
    line-height: 1.375;
}
.contact-form label {
    margin-bottom: 0.4em;
}
.btn {
    background: #EEE;
    border: 1px solid #CCC;
    border-radius: 0.2em;
    cursor: pointer;
    font-weight: 800;
    padding: 0.5em 0.75em;
    transition: background-color 0.2s;
}
.btn:hover,
.btn:focus {
    background: #E4E4E4;
}

.contact-form .btn {
    background: #4990b3;
    border-color: #4281A1;
    color: #FFF;
}
.contact-form .btn:hover,
.contact-form .btn:focus {
    background: #4281A1;
}

.links-social {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin: 0;
}
.links-social li {
    margin-right: 1em;
    display: inline;
}
.links-social img {
    transition: transform 0.2s;
}
.links-social a:hover img,
.links-social a:focus img {
    transform: scale( 1.2 );
}

/*
“Formidable” styles
*/
.frm_error_style,
.form-field {
    display: block;
    margin-bottom: 1em;
}
.frm_required_field label {
    font-weight: bold;
}
.frm_required,
.frm_error_style,
.frm_error {
    color: #D8411A;
}
.frm_error_style {
    background: #FFD6D1;
    padding: 1em;
    margin-left: -1em;
    margin-right: -1em;
    margin-bottom: 3em;
}
.frm_error {
    margin-bottom: 2em;
}
.frm_required.frm_hidden {
    display: inline;
}



/*

Footer

*/
.site-footer {
    color: #FFF;
    font-weight: 800;
    text-align: center;
}

.legalese {
    color: #DADDE3;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.35;
    margin: 6em auto 0;
    text-align: justify;
}
.legalese strong {
    color: #FFF;
}
.hed-legalese {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
}
