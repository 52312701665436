@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@keyframes rotating {
    0% {
        transform: rotate( 0deg );
    }
    100% {
        transform: rotate( 360deg );
    }
}
@keyframes flicker {
  0% {
    opacity: 0.27861;
  }

  25% {
    opacity: 0.83891;
  }
 65% {
    opacity: 0.71988;
  }

  100% {
    opacity: 0.24387;
  }
}
